import { Button, Divider, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import dayjs, { Dayjs } from 'dayjs';
import axios from "axios";
import ButtonBox from "../components/buttonBox/buttonBox";
import { ErrorSharp } from "@mui/icons-material";

function EscalationForm({ handleClose }) {
    const [formValues, setFormValues] = useState({
        type: 'No Show'
    });

    const [errors, setErrors] = useState([]);

    const handleChange = (e) => {
        setFormValues({
            ...formValues,
            [e.target.id]: e.target.value
        })
    }

    const submit = () => {
        let required = ['client', 'email', 'phone', 'details', 'address'];
        let arr = [];

        required.forEach(key => {
            if(!formValues[key] || formValues[key].length === 0) {
                console.log(key)
                arr.push(key)
            }
        })


        if(arr.length === 0) {
            formValues.user = localStorage.getItem('user');
            formValues.date = new Date().getTime();
            formValues.status = 'New';

            axios.post(`https://my-tb-cors.herokuapp.com/https://escalation-fns.azurewebsites.net/api/save?containerId=escalations`, formValues).then(res => {
                console.log(res)
                handleClose();
                setFormValues({
                    type: 'No Show'
                })
            })
        }
        else {
            setErrors(arr);
        }
    }

    return(
        <>
            <Typography variant='h5'>New Escalation</Typography>
            <Divider sx={{mb: 3, mt: 1}} />

            <Typography sx={{fontWeight: 'bold', mt: 3, mb: 2}}>Customer Info</Typography>
            <Grid container spacing={1}>
                <Grid item xs={12} md={6}>
                    <TextField
                        size='small'
                        fullWidth
                        label='Company'
                        onChange={handleChange}
                        id='company'
                        value={formValues.company || ''}
                        sx={{mb: 2}}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        size='small'
                        fullWidth
                        label='Store Number'
                        onChange={handleChange}
                        id='store'
                        value={formValues.store || ''}
                        sx={{mb: 2}}
                    />
                </Grid>
            </Grid>
            <TextField
                size='small'
                fullWidth
                label='Name'
                onChange={handleChange}
                id='client'
                value={formValues.client || ''}
                sx={{mb: 2}}
                helperText={(errors.indexOf('client') > -1 && (formValues.client?.length === 0 || !formValues.client)) ? 'This field is required' 
                : ''}
                error={errors.indexOf('client') > -1 && (formValues.client?.length === 0 || !formValues.client)}
            />
            <TextField
                size='small'
                fullWidth
                label='Address'
                onChange={handleChange}
                id='address'
                value={formValues.address || ''}
                sx={{mb: 2}}
                helperText={(errors.indexOf('address') > -1 && (formValues.address?.length === 0 || !formValues.address)) ? 'This field is required' 
                : ''}
                error={errors.indexOf('address') > -1 && (formValues.address?.length === 0 || !formValues.address)}
            />
            <TextField
                size='small'
                fullWidth
                label='Email'
                onChange={handleChange}
                id='email'
                value={formValues.email || ''}
                sx={{mb: 2}}
                helperText={(errors.indexOf('email') > -1 && (formValues.email?.length === 0 || !formValues.email)) ? 'This field is required' 
                : ''}
                error={errors.indexOf('email') > -1 && (formValues.email?.length === 0 || !formValues.email)}
            />
            <TextField
                size='small'
                fullWidth
                label='Phone Number'
                onChange={handleChange}
                id='phone'
                value={formValues.phone || ''}
                sx={{mb: 2}}
                helperText={(errors.indexOf('phone') > -1 && (formValues.phone?.length === 0 || !formValues.phone)) ? 'This field is required' 
                : ''}
                error={errors.indexOf('phone') > -1 && (formValues.phone?.length === 0 || !formValues.phone)}
            />

            <Typography sx={{fontWeight: 'bold', mt: 3, mb: 2}}>Complaint Info</Typography>
            <Grid container spacing={1}>
                <Grid item xs={12} md={6}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateTimePicker
                            renderInput={(props) => <TextField {...props} size='small' fullWidth />}
                            label="Call Time"
                            value={formValues.callTime || new Date()}
                            onChange={(newValue) => {
                                setFormValues({
                                    ...formValues,
                                    callTime: newValue.$d
                                })
                            }}
                            fullWidth
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormControl fullWidth>
                        <InputLabel id='type-id'>Escalation Type</InputLabel>
                        <Select
                            labelId='type-id'
                            fullWidth
                            size='small'
                            value={formValues.type}
                            onChange={(e) => setFormValues({
                                ...formValues,
                                type: e.target.value
                            })}
                            label='Escalation Type'
                        >
                            <MenuItem value='No Show'>No Show</MenuItem>
                            <MenuItem value='Bad Service'>Bad Service</MenuItem>
                            <MenuItem value='No Communications'>No Communications</MenuItem>
                            <MenuItem value='Other'>Other</MenuItem>
                        </Select>
                        {formValues.type === 'Other' &&
                            <FormHelperText>Please specify in the details section</FormHelperText>
                        }
                    </FormControl>
                </Grid>
            </Grid>
            <TextField
                size='small'
                fullWidth
                label='Details'
                onChange={handleChange}
                id='details'
                value={formValues.details || ''}
                sx={{mb: 2, mt: 2}}
                multiline
                minRows={5}
                helperText={(errors.indexOf('details') > -1 && (formValues.details?.length === 0 || !formValues.details)) ? 'This field is required' 
                : ''}
                error={errors.indexOf('details') > -1 && (formValues.details?.length === 0 || !formValues.details)}
            />

            <ButtonBox>
                <Button
                    sx={{fontSize: '12px'}}
                    variant='outlined'
                    onClick={handleClose}
                >
                    cancel
                </Button>
                <Button
                    sx={{fontSize: '12px', ml: 1}}
                    variant='contained'
                    onClick={submit}
                >
                    submit
                </Button>
            </ButtonBox>
        </>
    )
}

export default EscalationForm;