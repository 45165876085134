import { Button, Box, Typography,  } from "@mui/material";
import React from "react";
import CenteredDiv from "../../components/centeredDiv/centeredDiv";
import LoginLogic from "./loginLogic";
import bg from '../../images/bg.jpeg';
import logo from '../../images/logo.png';

function Login() {
    const { msalLogin } = LoginLogic();

    return(
        <>
            {/* <CenteredDiv>
                <Button
                    onClick={msalLogin}
                >
                    press to login
                </Button>
            </CenteredDiv> */}

            <Box sx={{width: '100%', height: '100vh'}}>
                <img src={bg} alt='' style={{width: '100%', height: '100vh', position: 'relative'}} />
                <Box sx={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%,-50%)', zIndex: 1, bgcolor: 'black', borderRadius: '5px', width: '300px', textAlign: 'center', p: 4}}>
                    <img src={logo} alt='' style={{width: '180px'}} />
                    <Typography variant='h5' sx={{color: 'white', mt: 2, mb: 4, letterSpacing: '.04em'}}>ESCALATION MGMT</Typography>
                    <Button onClick={msalLogin} variant='contained' fullWidth sx={{bgcolor: '#0b56a4'}}>
                        login
                    </Button>
                </Box>
             </Box>
        </>
    )
}

export default Login;