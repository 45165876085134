import { Add, CalendarMonth, Clear, FilterAltSharp, FolderOpenSharp, Person, Reorder } from "@mui/icons-material";
import { Box, Button, ButtonGroup, Divider, Grid, IconButton, Menu, MenuItem, Modal, Paper, Select, Stack, TextField, Typography } from "@mui/material";
import { DataGridPro } from "@mui/x-data-grid-pro";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ButtonBox from "../../components/buttonBox/buttonBox";
import PageLayout from "../../components/pageLayout/pageLayout";
import EscalationForm from "../../forms/escalation";
import style from "../../styles/modal";

function Escalations() {
    const navigate = useNavigate();
    const [ escalations, setEscalations ] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selected, setSelected] = useState([]);
    const [open, setOpen] = useState(false);
    const [formValues, setFormValues] = useState({});
    const [activeEscalation, setActiveEscalation] = useState({});
    const [view, setView] = useState('info');
    const [displayNote, setDisplayNote] = useState(false);
    const [newNote, setNewNote] = useState('');
    const [anchorEl, setAnchorEl] = useState(null);
    const menuOpen = Boolean(anchorEl);
    const [unfiltered, setUnfiltered] = useState([])

    useEffect(() => {
        axios.get('https://my-tb-cors.herokuapp.com/https://escalation-fns.azurewebsites.net/api/getall?containerId=escalations').then(res => {
            setLoading(false);
            setEscalations(res.data.filter(escalation => !escalation.archived));
            setUnfiltered(res.data);
        });
    }, []);

    const columns = [
        {field: 'date', headerName: 'Date Submitted', flex: .5, valueGetter: (params) => new Date(params.row.date).toLocaleDateString()},
        {field: 'user', headerName: 'Submitted By', flex: .5},
        {field: 'client', headerName: 'Client', flex: 1},
        {field: 'type', headerName: 'Type', flex: .5},
        {
            field: 'status', 
            headerName: 'Status', 
            flex: 1,
            renderCell: (params) => {
                if(params.row.status === 'New') {
                    return <Typography variant='body2' sx={{fontWeight: 'bold'}}>New</Typography>
                }
                else if(params.row.status === 'In Progress - URGENT') {
                    return <Typography variant='body2' sx={{color: 'red'}}>URGENT</Typography>
                }
                else if(params.row.status === 'In Progress') {
                    return <Typography variant='body2' sx={{color: '#ffbb33'}}>In Progress</Typography>
                }
                return <Typography variant='body2' sx={{color: 'green'}}>Resolved</Typography>
            }
        },
        {field: 'archived', headerName: 'Archived', renderCell: (params) => {
            if(params.row.archived) {
                return <Typography variant='body2'>Yes</Typography>
            }
            return <Typography variant='body2'>No</Typography>
        }}
    ];

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    function selectRows(e) {
        setSelected(e);
    };

    function archive() {
        selected.forEach(id => {
            axios.post(`https://my-tb-cors.herokuapp.com/https://escalation-fns.azurewebsites.net/api/update?containerId=escalations&id=${id}`, {
                archived: true
            }).then(res => {
                setEscalations(escalations.filter(escalation => escalation.id !== id))
            })
        })
    };

    function updateStatus(e) {
        axios.post(`https://my-tb-cors.herokuapp.com/escalation-fns.azurewebsites.net/api/update?containerId=escalations&id=${activeEscalation.id}`, {
            status: e.target.value
        }).then(res => {
            console.log(res);
            setActiveEscalation({
                ...activeEscalation,
                status: e.target.value
            });
            setEscalations(escalations.map(escalation => {
                if(escalation.id === activeEscalation.id) {
                    return {
                        ...activeEscalation,
                        status: e.target.value
                    }
                }
                return escalation
            }))
        })
    };

    function saveNote() {
        let arr = activeEscalation.notes || [];
        console.log(arr);
        arr.unshift({
            note: newNote,
            date: new Date().getTime(),
            user: localStorage.getItem('user')
        });
        console.log(arr);
        axios.post(`https://my-tb-cors.herokuapp.com/escalation-fns.azurewebsites.net/api/update?containerId=escalations&id=${activeEscalation.id}`, {
            notes: arr
        }).then(() => {
            setActiveEscalation({
                ...activeEscalation,
                notes: arr
            });
            setDisplayNote(false);
            setNewNote('');
        })
    };

    function filter(target) {
        if(target === 'all') {
            setEscalations(unfiltered);
        }
        else if(target === 'unarchived') {
            setEscalations(unfiltered.filter(escalation => !escalation.archived));
        }
        else {
            setEscalations(unfiltered.filter(escalation => escalation.archived))
        }

        setAnchorEl(null);
    };

    return (
        <PageLayout>
            <Modal
                open={open}
                onClose={handleClose}
            >
                <Box sx={{...style, maxWidth: '600px', p: 3, maxHeight: '90vh', overflowY: 'auto'}}>
                    <EscalationForm handleClose={handleClose} />
                </Box>
            </Modal>
            <Box sx={{pb: 1, mt: 2}}>
                <Button
                    variant='contained'
                    sx={{fontSize: '10px'}}
                    onClick={handleOpen}
                >
                    new
                </Button>
                <Button
                    sx={{mr: 1, ml: 2}}
                    onClick={archive}
                >
                    <FolderOpenSharp sx={{mr: 1}} /> archive selected
                </Button>
                <Button
                    sx={{mr: 1, ml: 1}}
                    onClick={(e) => setAnchorEl(e.currentTarget)}
                >
                    <FilterAltSharp sx={{mr: 1}} /> filter
                </Button>
                <Menu
                    anchorEl={anchorEl}
                    open={menuOpen}
                    onClose={() => setAnchorEl(null)}
                >
                    <MenuItem onClick={() => filter('all')}>All</MenuItem>
                    <MenuItem onClick={() => filter('unarchived')}>Unarchived</MenuItem>
                    <MenuItem onClick={() => filter('archived')}>Archived</MenuItem>
                </Menu>
            </Box>

            <Stack direction='row' spacing={1}>
                <Box sx={Object.keys(activeEscalation).length > 0 ? {width: '66%'} : {width: '100%'}}>
                    <DataGridPro
                        loading={loading}
                        rows={escalations}
                        columns={columns}
                        autoPageSize
                        autoHeight
                        sx={{bgcolor: 'white'}}
                        density='compact'
                        pageSize={40}
                        checkboxSelection
                        onSelectionModelChange={selectRows}
                        onRowClick={(params) => setActiveEscalation(params.row)}
                    />
                </Box>
                {Object.keys(activeEscalation).length > 0 &&
                    <Paper sx={{width: '34%', border: '1px solid rgba(224, 224, 224, 1)', maxHeight: '100vh', overflowY: 'auto'}} elevation={0}>
                        <Stack direction='row' mt={1}>
                            <IconButton
                                onClick={() => setActiveEscalation({})}
                            >
                                <Clear />
                            </IconButton>
                            <ButtonGroup 
                                value={view}
                                size='small'
                                sx={{height: '30px', margin: '0 auto', mt: 1, width: '150px'}}
                            >
                                <Button onClick={() => setView('info')} variant={view === 'info' ? 'contained' : 'outlined'} size='small'>Info</Button>
                                <Button onClick={() => setView('notes')} value='notes' variant={view === 'notes' ? 'contained' : 'outlined'} size='small'>Notes</Button>
                            </ButtonGroup>
                        </Stack>

                        <Divider sx={{mt: 1}} />
                        {view === 'info' &&
                            <Box sx={{mt: 2, px: 2, pb: 3}}>
                                <Grid container>
                                    <Grid item xs={6}>
                                        <Typography variant='body2' sx={{fontWeight: 'bold', width: '100%', mb: 1}}>Date Submitted:</Typography>
                                        <Typography variant='body2'>{new Date(activeEscalation?.date).toLocaleDateString()}</Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography variant='body2' sx={{fontWeight: 'bold', mb: 1}}>By:</Typography>
                                        <Typography variant='body2'>{activeEscalation?.user}</Typography>    
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant='body2' sx={{fontWeight: 'bold', mb: 1, mt: 3}}>Status:</Typography>
                                        <Select
                                            value={activeEscalation?.status}
                                            fullWidth
                                            size='small'
                                            onChange={updateStatus}
                                        >
                                            <MenuItem value='New'>New</MenuItem>
                                            <MenuItem value='In Progress - URGENT' sx={{color: 'red'}}>In Progress - URGENT</MenuItem>
                                            <MenuItem value='In Progress' sx={{color: '#ffbb33'}}>In Progress</MenuItem>
                                            <MenuItem value='Resolved' sx={{color: 'green'}}>Resolved</MenuItem>
                                        </Select>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant='body2' sx={{fontWeight: 'bold', mb: 1, mt: 3}}>Client:</Typography>
                                        <Typography variant='body2'>{activeEscalation?.client}</Typography>
                                        <Typography variant='body2'>{activeEscalation?.phone}</Typography> 
                                        <Typography variant='body2'>{activeEscalation?.email}</Typography>
                                        {activeEscalation.address &&
                                            <Typography variant='body2'>{activeEscalation?.address}</Typography>
                                        }
                                        {activeEscalation.company &&
                                            <Typography variant='body2'>Company: {activeEscalation?.company}</Typography>
                                        } 
                                        {activeEscalation.store &&
                                            <Typography variant='body2'>Store #: {activeEscalation?.store}</Typography>
                                        }
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant='body2' sx={{fontWeight: 'bold', mb: 1, mt: 3}}>Details:</Typography>
                                        <Typography variant='body2'>{activeEscalation?.details}</Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                        }
                        {view === 'notes' &&
                            <Box sx={{mt: 2, px: 2, pb: 3, maxHeight: '100vh', overflowY: 'auto'}}>
                                {!displayNote &&
                                    <Button
                                        onClick={() => setDisplayNote(true)}
                                        startIcon={<Add />}
                                    >
                                        note
                                    </Button>
                                }
                                {displayNote &&
                                    <>
                                        <TextField
                                            multiline
                                            value={newNote}
                                            fullWidth
                                            onChange={(e) => setNewNote(e.target.value)}
                                            label='Note Details'
                                            minRows={3}
                                            sx={{mb: 1}}
                                        />
                                        <ButtonBox>
                                            <Button
                                                variant='outlined'
                                                onClick={() => setDisplayNote(false)}
                                                sx={{ml: 1, fontSize: '10px'}}
                                                color='error'
                                            >
                                                cancel
                                            </Button>
                                            <Button
                                                variant='contained'
                                                onClick={saveNote}
                                                sx={{ml: 1, fontSize: '10px'}}
                                                color='success'
                                            >
                                                save
                                            </Button>
                                        </ButtonBox>
                                    </>
                                }
                                {activeEscalation.notes?.map(note => {
                                    return (
                                        <>
                                            <Divider sx={{my: 2}} />
                                            <Stack direction='row' spacing={3}>
                                                <Typography variant='body2' sx={{mb: 1}}>
                                                    <CalendarMonth sx={{verticalAlign: 'middle', fontSize: '16px', color: '#6f7378', mr: 1}} />{new Date(note.date).toLocaleDateString()}
                                                </Typography>
                                                <Typography variant='body2' sx={{mb: 1}}>
                                                    <Person sx={{verticalAlign: 'middle', fontSize: '16px', color: '#6f7378', mr: 1}} /> {note.user}
                                                </Typography>
                                            </Stack>
                                            <Typography variant='body2' sx={{ mb: 1}}>
                                                <Reorder sx={{verticalAlign: 'middle', fontSize: '16px', color: '#6f7378', mr: 1}} /> {note.note}
                                            </Typography>
                                        </>
                                    )
                                })}
                            </Box>
                        }
                    </Paper>
                }
            </Stack>
        </PageLayout>
    )
}

export default Escalations;