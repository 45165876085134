import React, { useState } from "react";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate } from "react-router-dom";
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';
import { styled, alpha } from '@mui/material/styles';
import { Logout } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import logoSmall from '../../images/logoSmall.png';

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  }));
  
  const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }));

  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        width: '12ch',
        '&:focus': {
          width: '20ch',
        },
      },
    },
  }));

function PageLayout(props) {
    const { children } = props;
    const navigate = useNavigate();
    const [searchTerm, setSearchTerm] = useState('');

    function logout() {
        localStorage.removeItem('account');
        localStorage.removeItem('access token');
        localStorage.removeItem('user');
        localStorage.removeItem('escalationMail');
        navigate('/');
    }

    function search() {
        console.log(searchTerm)
    }

    function handleChange(e) {
        setSearchTerm(e.target.value);
    }
    

    return(
        <Box sx={{ flexGrow: 1, minHeight: '100vh', bgcolor: '#f2f2f2' }}>
        <AppBar position="static">
          <Toolbar>
            {/* <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="open drawer"
              sx={{ mr: 2 }}
            >
              <MenuIcon />
            </IconButton> */}
            <img src={logoSmall} style={{marginRight: '15px'}} />
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
            >
              ESCALATIONS
            </Typography>
            {/* <Search>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder="Search…"
                inputProps={{ 'aria-label': 'search' }}
                onChange={handleChange}
                onKeyDown={search}
              />
            </Search> */}
            <Tooltip title='Logout'>
                <Button
                    endIcon={<Logout />}
                    onClick={logout}
                    color='inherit'
                >
                    logout
                </Button>
                {/* <IconButton
                    color='inherit'
                    onClick={logout}
                >
                    <Logout />
                </IconButton> */}
            </Tooltip>
          </Toolbar>
        </AppBar>
        <Box p={1}>
            { children }
        </Box>
      </Box>
    )
}

export default PageLayout;