import './App.css';
import { Routes, Route } from 'react-router-dom';
import Login from './pages/login/login';
import Escalations from './pages/escalations/escalations';
import OpenEscalation from './pages/openEscalation/openEscalation';
import { LicenseInfo } from '@mui/x-license-pro';

LicenseInfo.setLicenseKey('f94999c7685f2608811fb8b48423f2baTz00ODIyNyxFPTE2OTA3Mzk3NTYzNjgsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');

function App() {
  return (
    <Routes>
      <Route exact path='/' element={<Login />} />
      <Route exact path='/escalations' element={<Escalations />} />
      <Route exact path='/escalations/:id' element={<OpenEscalation />} />
    </Routes>
  );
}

export default App;