import { useNavigate } from "react-router-dom";
import * as msal from '@azure/msal-browser';

function LoginLogic() {
    const navigate = useNavigate();
    const msalConfig = {
        auth: {
            clientId: 'c5f2276c-c129-44d7-8d71-281ce8a2c37b'
        }
    }
    const msalInstance = new msal.PublicClientApplication(msalConfig);

    async function msalLogin() {
        const loginResponse = await msalInstance.loginPopup({}); 
        localStorage.setItem('account', JSON.stringify(loginResponse.account));
        localStorage.setItem('access token', loginResponse.accessToken);
        localStorage.setItem('user', loginResponse.account.name);
        localStorage.setItem('escalationMail', loginResponse.account.username);
        navigate('/escalations')
    }


    return {
        msalLogin
    }

}

export default LoginLogic;